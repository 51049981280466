:root {
  --text-color: #000;
  --main-color: #5833eb;
}

* {
  box-sizing: inherit;
}

html {
  font-size: 10px;
  line-height: 24px;
  font-family: 'Lexend', sans-serif;
  box-sizing: border-box;
}

.grid__full-w {
  width: 100%;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

button {
  border-radius: 30px;
  border: none;
}

.position_ab-center {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.jus-between {
  justify-content: space-between;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #777e90;
  margin: 0;
}

button:hover {
  opacity: 0.5;
  cursor: pointer;
}

.flex1 {
  flex: 1;
}
